<template>
  <div class="enjoy">
    <img
      class="iconimg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/top.jpg"
    />
    <div class="form">
      <img
        class="one"
        v-show="num == 1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger1.gif"
      />
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="changePhone"
      />
      <img
        class="two"
        v-show="num == 2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger2.gif"
      />
      <img
        class="three"
        v-show="num == 3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger3.gif"
      />
      <img
        class="four"
        v-show="num == 4"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/stepFinger4.gif"
      />
      <template>
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          :class="['input-code', { 'two-cell': num == 2 }]"
        >
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/submit-lijidinggou.gif"
        class="submit"
        @click="onSubmit"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#ff850f"
          v-model="checked"
          icon-size="16px"
        >
          <div class="checked-label">
            我已阅读并同意<span class="book" @click="showModal1"
              >《隐私条款》</span
            ><span class="book" @click="showModal2">《受理协议》</span>
          </div>
        </van-checkbox>
      </div>
    </div>
    <div class="info">
      <div class="info-title">活动规则</div>
      <pre class="info-text">
一、资费详情
<span>5G优享包-20元15GB/月</span>，20元享15GB国内通用流量，首年加赠5GB/月，<span>合约期1年</span>，合约到期后资费20元15GB/月。
二、详细规则
1.<span>产品办理后立即生效，活动生效期间不能叠加办理同一档位业务。</span>
2.5G优享包流量为国内通用流量(不含港澳台)，活动加赠的流量仅限在海南使用，所有活动流量均不能转赠、不能共享、不可结转，<span>5G优享包流量使用优先于主套餐，加赠流量在主套餐后使用</span>。
3.<span>活动到期前给客户发送到期提醒短信，订购的流量包自动保留，</span>活动有效期最后一个月客户可根据到期提醒短信致电10086退订<span>5G优享包流量包</span>，退订次月生效。
4.合约期1年，期间不可办理携号转网，如有其它合约，合约期顺延叠加。活动期间如终止活动，<span>5G优享包以及加赠的流量包同时失效。</span>
5.退订方式可拨打10086或到营业厅办理，更多详情请咨询10086。
      </pre>
    </div>
    <img
      class="iconimg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/bottom.jpg"
    />
    <van-popup
      v-model="showSubmitDialog"
      :close-on-click-overlay="false"
      style="background: none"
    >
      <div class="dialog_wrap">
        <img
          class="dialog-img"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/dialog_bg.png"
        />
        <img
          class="cancel"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/cancel.png"
          @click="showSubmitDialog = false"
        />
        <img
          class="confirm"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/confirm.png"
          @click="handleSubmit"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  components: {},
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "69901149",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      showSubmitDialog: false,
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/YDHaiNan/5GEnjoymentPackage`;
    },
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, phone, "退出")
      );
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href),
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async changePhone() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone = "" } = this.form;
      if (!filterPhone.test(phone)) return;
    },
    getAuthCode() {
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        if (this.yzm_loading) return;
        this.yzm_loading = true;
        getVerificationCode(
          JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
        )
          .then((res) => {
            this.yzm_loading = false;
            if (res.code !== 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              setTimeout(() => {
                window.location.replace(
                  this.tailJumpUrl(
                    this.link,
                    `QIUMORONGHE`,
                    this.form.phone,
                    "验证码下发失败"
                  )
                );
              }, 3000);
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            this.yzm_loading = false;
          });
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        this.dialog = false;
        if (res.code !== 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "成功"
              )
            );
          }, 3000);
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意相关协议",
        });
      } else {
        this.showSubmitDialog = true;
      }
    },
    showModal1() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "隐私条款",
        message: `《关于客户个人信息收集、使用规则的公告》
尊敬的客户：

       根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。 
如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。 
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息： 
(a)事先获得客户的明确授权； 
(b)根据有关的法律法规要求； 
(c)按照相关司法机关和/或政府主管部门的要求； 
(d)为维护社会公众的利益所必需且适当； 
(e)为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
    showModal2() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "受理协议",
        message: `一、资费详情
 5G优享包-20元15GB/月，20元享15GB国内通用流量，首年加赠5GB/月，合约期1年，合约到期后资费20元15GB/月。 
二、详细规则 
产品办理后立即生效，活动生效期间不能叠加办理同一档位业务。 
2.5G优享包流量为国内通用流量(不含港澳台)，活动加赠的流量仅限在海南使用，所有活动流量均不能转赠、不能共享、不可结转，5G优享包流量使用优先于主套餐，加赠流量在主套餐后使用。 
活动到期前给客户发送到期提醒短信，订购的流量包自动保留，活动有效期最后一个月客户可根据到期提醒短信致电10086退订5G优享包流量包，退订次月生效。 
合约期1年，期间不可办理携号转网，如有其它合约，合约期顺延叠加。活动期间如终止活动，5G优享包以及加赠的流量包同时失效。 
5.退订方式可拨打10086或到营业厅办理，更多详情请咨询10086。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.enjoy {
  background-color: #e33c50;

  .iconimg {
    width: 100%;
  }
  .form {
    position: relative;
    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    .one {
      transform: translate(80px, -80px);
    }

    .two {
      width: 166px;
      right: 40px;
      top: 50px;
    }

    .three {
      width: 456px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 310px;
      transform: translate(300px, 300px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 90px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #fff;
          font-size: 30px;
          font-weight: bold !important;
          font-weight: normal;
          background: #ff850f;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 1.2s infinite;

      @keyframes identifier {
        0% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.9);
        }
      }
      &.none {
        pointer-events: none;
      }

      &.bottom {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 634px;
        bottom: 70px;
      }
    }
    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;

      ::v-deep .van-checkbox {
        width: auto;
      }
      .checked-label {
        color: #fff !important;
      }
      .book {
        font-weight: 400;
        color: #f8f107;
      }
    }
  }

  .info {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    padding: 0px 28px 0px;
    .info-title {
      margin: 50px auto 40px;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
    }

    .info-text {
      white-space: pre-wrap;
      font-size: 30px;
      line-height: 1.4;
      span {
        color: yellow;
      }
    }
  }

  .dialog_wrap {
    width: 720px;
    height: 707px;
    position: relative;
    .dialog-img {
      width: 100%;
      height: 100%;
    }
    .cancel {
      position: absolute;
      left: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
    .confirm {
      position: absolute;
      right: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
  }
}
</style>
